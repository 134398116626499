import React from "react"

const useModalProps = ({ acceptType = 'withAccept', initialOpen = false } = {}) => {

  const initialStatus = { open: initialOpen, acceptType, modalData: null };
  const [modalStatus, setModalStatus] = React.useState(initialStatus);

  const openModal = ({ override = {} }) => {
    document.body.classList.add('modal-open');
    setModalStatus({ ...modalStatus, ...override, open: true, type: override?.type || '' })
  }

  const changeModal = (type) => {
    document.body.classList.add('modal-open');
    setModalStatus({ ...modalStatus, open: true, type: type || '' })
  }

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setModalStatus({ ...modalStatus, open: false })
  }

  const closeModalAndClean = () => {
    document.body.classList.remove('modal-open');
    setModalStatus({ ...initialStatus, open: false })
  }

  const acceptAndCloseModal = () => {
    closeModal()
  }

  const modalProps = {
    modalStatus,
    acceptAndCloseModal,
    closeModal,
    openModal,
    changeModal,
    closeModalAndClean,
  }
  return { modalProps };
}

export default useModalProps;